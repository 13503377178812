import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useController } from "react-scroll-parallax"
import RadioButton from "../RadioButton/RadioButton"

const Logo = "/img/MA_logo.svg"

const Header = props => {
  let pathname = props.location.pathname

  const [sortedSlugs, setSortedSlugs] = useState(null)

  const { parallaxController } = useController()
  const delayedHandler = () => {
    setTimeout(() => parallaxController.update(), 50)
  }

  useEffect(() => {
    const getSortedSlugs = window.localStorage.getItem("sortedSlugs")
    const parsedSortedSlugs = JSON.parse(getSortedSlugs)
    setSortedSlugs(parsedSortedSlugs)
  }, [])

  const artworkId = props.id
  const artworkDate = props.date
  const artworkAuthor = props.author
  const artworkSurname = props.surname
  const artworkCategory = props.category

  let content = props.isContentIn
  const slugs = sortedSlugs ? sortedSlugs : []

  const { currentPage, numPages } = props.pageContext
  const currentIndex = slugs.findIndex(item => {
    // console.log(item+" : "+currentPage)
    return "/" + item === currentPage
  })

  const isFirst = currentIndex === 0
  const isLast = currentIndex === numPages

  const prevPage =
    currentIndex - 1 === -1 ? false : "/" + slugs[currentIndex - 1]
  const nextPage =
    currentIndex + 1 >= numPages ? false : "/" + slugs[currentIndex + 1]

  const checkWindow = type => {
    if (typeof window !== "undefined") {
      if (type === "radioButton") {
        return window.localStorage.getItem("isAlphabetically") === "true"
          ? false
          : true
      } else if (type === "emptyRadioButton") {
        return window.localStorage.getItem("isAlphabetically") === "true"
          ? true
          : false
      }
    }
  }

  const [radioButton, setRadioButton] = useState(checkWindow("radioButton"))
  const [emptyRadioButton, setEmptyRadioButton] = useState(
    checkWindow("emptyRadioButton")
  )

  const handleKeyDown = (e, func) => {
    if (
      (e.keyCode === "Enter" || e.which === 13 || e.key === "Enter") &&
      func === "Alphabetically"
    ) {
      props.sort(false)
      setRadioButton(true)
      setEmptyRadioButton(false)
      delayedHandler()
    } else if (
      (e.keyCode === "Enter" || e.which === 13 || e.key === "Enter") &&
      func === "Chronologically"
    ) {
      props.sort(true)
      setRadioButton(false)
      setEmptyRadioButton(false)
      delayedHandler()
    }
  }

  let headerMarkup

  if (pathname === "/") {
    headerMarkup = (
      <header className="main-header">
        <div className="main-header__company">
          <Link to="/">
            <img className="main-header__company-logo" src={Logo} alt="Logo" />
            <p className="main-header__company-title">
              <span>SBÍRKA UMĚNÍ</span>
              <span>MAGNUS ART</span>
            </p>
          </Link>
        </div>
        <div className="main-header__navigation main-header__navigation-hide">
          <ul>
            <li>
              <Link to="/info">INFO</Link>
            </li>
            <li className="text-left">
              <button
                className="button"
                onClick={() => {
                  props.sort(true)
                  setRadioButton(true)
                  setEmptyRadioButton(false)
                  delayedHandler()
                }}
                onKeyDown={e => handleKeyDown(e, "Alphabetically")}
              >
                <RadioButton checked={radioButton} />
                CHRONOLOGICKY
              </button>
            </li>
            <li className="text-left">
              <button
                className="button"
                onClick={() => {
                  props.sort(false)
                  setRadioButton(false)
                  setEmptyRadioButton(true)
                  delayedHandler()
                }}
                onKeyDown={e => handleKeyDown(e, "Chronologically")}
              >
                <RadioButton checked={emptyRadioButton} />
                ABECEDNĚ
              </button>
            </li>
          </ul>
        </div>
        <div className="main-header__navigation-mobile">
          <ul>
            <li>
              <Link to="/info">INFO</Link>
            </li>
            <li className="text-left">
              <button
                className="button"
                onClick={() => {
                  props.sort(true)
                  setRadioButton(true)
                  setEmptyRadioButton(false)
                }}
                onKeyDown={e => handleKeyDown(e, "Alphabetically")}
              >
                <RadioButton checked={radioButton} />
                0&mdash;9
              </button>
            </li>
            <li className="text-left">
              <button
                className="button"
                onClick={() => {
                  props.sort(false)
                  setRadioButton(false)
                  setEmptyRadioButton(true)
                }}
                onKeyDown={e => handleKeyDown(e, "Chronologically")}
              >
                <RadioButton checked={emptyRadioButton} />
                A&mdash;Z
              </button>
            </li>
          </ul>
        </div>
      </header>
    )
  } else if (content === true && slugs) {
    headerMarkup = (
      <header className="content-header" key={artworkId}>
        <div className="content-header__headline">
          <p className="content-header__headline-date">{artworkDate}</p>
          <p className="content-header__headline-title">
            <span>
              {artworkAuthor} {artworkSurname}
            </span>
            <span>{artworkCategory}</span>
          </p>
        </div>
        <div className="content-header__navigation">
          <ul className="content-header__navigation-list">
            {!isFirst && prevPage && (
              <li className="navArrow">
                <Link to={prevPage}>&#x2190;</Link>
              </li>
            )}
            {!isLast && nextPage && (
              <li className={`navArrow ${isFirst === true ? "nav-first" : ""}`}>
                <Link to={nextPage}>&#x2192;</Link>
              </li>
            )}
            <li>
              <Link to="/">×</Link>
            </li>
          </ul>
        </div>
      </header>
    )
  } else if (pathname === "/info") {
    headerMarkup = (
      <header className="main-header">
        <div className="main-header__company">
          <Link to="/">
            <img className="main-header__company-logo" src={Logo} alt="Logo" />
            <p className="main-header__company-title">
              <span>SBÍRKA UMĚNÍ</span>
              <span>MAGNUS ART</span>
            </p>
          </Link>
        </div>
        <div className="main-header__navigation info-header hide-info-navigation">
          <ul>
            <li>INFO</li>
          </ul>
        </div>
      </header>
    )
  }

  return <>{headerMarkup}</>
}

export default Header
