import React from "react";
import Radio from "../../assets/RadioButton.svg";
import RadioEmpty from "../../assets/RadioButtonCircle.svg";

const RadioButton = (props) => {
    return (
        <>
            { props.checked === true ? <Radio className="radio" /> : <RadioEmpty className="radio" /> }
        </>
    )
}

export default RadioButton;