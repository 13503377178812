import React, { useState, useEffect } from "react"

const Cookies = () => {
  useEffect(() => {
    if (localStorage.getItem("cookies") === "true") {
      setIsCookiesSet(true)
    }
    if (localStorage.getItem("cookies") !== "true") {
      setOpen(true)
    }
  }, [])

  const [open, setOpen] = useState(false)
  const [isCookiesSet, setIsCookiesSet] = useState(false)

  const setIsOpen = () => {
    setOpen(false)
    localStorage.setItem("cookies", true)
  }

  const handleKeyCookies = e => {
    if (e.keyCode === "Enter" || e.which === 13 || e.key === "Enter") {
      setOpen(false)
      localStorage.setItem("cookies", true)
    }
  }

  return (
    <>
      {isCookiesSet ? null : (
        <div className="cookies" style={{ display: open ? "flex" : "none" }}>
          <p>
            J&T Banka na této webové stránce používá cookies. Chcete-li se
            dozvědět více, přejděte do sekce{" "}
            <a
              href="https://www.jtbank.cz/osobni-udaje/"
              target="_blank"
              rel="noreferrer"
            >
              Osobní údaje
            </a>{" "}
            nebo{" "}
            <a
              href="https://www.jtbank.cz/bezpecnostni-informace/"
              target="_blank"
              rel="noreferrer"
            >
              Nakládání s cookies
            </a>
            .
          </p>
          <button onClick={setIsOpen} onKeyDown={e => handleKeyCookies(e)}>
            rozumím
          </button>
        </div>
      )}
    </>
  )
}
export default Cookies
