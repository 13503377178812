import React from "react"

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="main-footer__contact">
        <p className="medium">&copy; J&amp;T Banka 2020</p>
        <p className="medium">SBÍRKA UMĚNÍ MAGNUS ART</p>
        <p className="mb-1 medium">Investujeme do toho, co nás obohacuje.</p>

        <p>Podmínky užití</p>
        <p className="mb-1">
          <a
            href="https://www.jtbank.cz/osobni-udaje/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Zásady zpracování osobních údajů
          </a>
        </p>

        <p>
          Design ●{" "}
          <a
            href="https://www.milann.info/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Studio M Nedvěd
          </a>
        </p>
        <p>
          Web development ●{" "}
          <a
            href="http://www.code8.cz/"
            rel="noopener noreferrer"
            target="_blank"
          >
            code 8
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
